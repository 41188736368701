import _ from 'lodash';
import React from 'react';
import BookTeaser from '../../BookTeaser';
import PageHeader from '../../PageHeader';
import QuoteImage from '../../QuoteImage';
import data from '../../../data/letGoAnxiety';

import styles from '../books.module.scss';

const LetGoAnxiety = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';
  const quoteImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1609527811/letGoBookMountains.jpg';

  const learnList = _.map(data.learn, (skill, index) => {
    return <li key={index}>{skill}</li>;
  });

  const bookPraise = _.map(data.advancePraise, ({ quote, author }) => {
    return (
      <div className={styles.book} key={_.uniqueId()}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: quote }} />
        <br />
        <br />
        <p style={{ textAlign: 'right' }} className={styles.copy}>
          {' '}
          &#8212;<i>{author}</i>
        </p>
      </div>
    );
  });

  return (
    <main>
      <PageHeader pageName="Let Go of Anxiety" pageCategory="Books" headerImage={background} />
      <div className={styles.buyBookWrapperTop}>
        <BookTeaser bookName="Let Go of Anxiety" shouldLinkToRoute={false} shouldShowPreview />
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.header}>
          Is anxiety sabotaging your passions, goals, and dreams? Then it{`'`}s time to ACT!
        </h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.intro }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.headerSmall}>In this book, you will learn how to:</h2>
        <ul>{learnList}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.recognize }} />
      </div>

      <QuoteImage image={quoteImage} maxWidth={540} quote={data.imageQuote} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Advance Praise</h2>
        {bookPraise}
      </div>

      <div className={styles.buyBookWrapperBottom}>
        <BookTeaser bookName="Let Go of Anxiety" shouldLinkToRoute={false} shouldShowPreview />
      </div>
    </main>
  );
};

export default LetGoAnxiety;
