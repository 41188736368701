const letGoAnxiety = {
  imageQuote:
    '<p>“Life is about ACTing—doing what matters most to you. Mountaintops and grand' +
    ' vistas&nbsp;await."</p><p>ANNABELLA HAGEN</p>',
  intro:
    'In <i>Let Go of Anxiety: Climb Life’s Mountains with Peace, Purpose, and Resilience</i>,' +
    ' Annabella' +
    ' Hagen uses a' +
    ' powerful and effective behavioral treatment called ACT (acceptance and commitment therapy). Shown to be' +
    ' successful for multiple psychological conditions&#8212;including anxiety, panic, avoidance, perfectionism,' +
    ' comparison, uncertainty, and procrastination&#8212;ACT will teach you how to focus your time, attention, and' +
    ' efforts in moving toward the things that matter most to you.',
  learn: [
    'Change the way you view and respond to anxiety, uncertainty, and self-doubt',
    'Develop flexibility in the way you think and act',
    'Incorporate mindfulness into your everyday life',
    'Cultivate more self-compassion',
    'Live a vital and meaningful life',
  ],
  recognize:
    'Recognize that the vision you have for yourself is possible when you accept, choose, and take action' +
    ' instead of letting your anxious mind act on you.',
  advancePraise: [
    {
      quote:
        '“If you’re lost on Anxiety Mountain as a dark night of your soul falls about you, it might be best to' +
        ' remember to slow down, take a breath, and turn on [a light]. Metaphorically, that’s what this simple book' +
        ' is—it’s that light. If you read it, your journey down Anxiety Mountain will become clearer, kinder, and' +
        ' safer... Highly recommended.”',
      author:
        'Steven C. Hayes, Foundation Professor of Psychology, University of Nevada, Originator and Co-developer of' +
        ' Acceptance and Commitment Therapy',
    },
    {
      quote:
        "“Hagen's book uses metaphors and stories to explain how our brain processes thoughts and events. She" +
        ' offers techniques and analogies for those of us living with anxiety, to work with our mind, instead of against it. Her writing style is easy to understand, making vital tools accessible to all. This book is a great resource for anyone wanting to learn more about coping with small bouts of stress to chronic anxiety. It was helpful for me and time well spent reading this book.”',
      author: 'Naomi Flinders',
    },
    {
      quote:
        '“This is a witty, wise, optimistic and practical guide. The exercises are helpful and relevant; the' +
        ' examples make complex principles clear; and the breadth of the work can be life-changing for anyone with anxiety. Taken in small doses or read as a whole, the principles in this work offer a hopeful path to a richer, fuller life. Thank you, Ms. Hagen, for guiding your readers to a more freeing perspective!”',
      author: 'Dianne L. Nielsen, PhD',
    },
    {
      quote:
        '“I had the opportunity to work with Annabella when I was a student reporter for <i>The Daily Universe</i>' +
        ' at' +
        ' Brigham Young University. Her extensive knowledge and expertise surrounding mental health brought great' +
        ' value to the articles I wrote covering topics on OCD disorders and anxiety. Annabella has a gift for' +
        ' breaking down the complex nature of mental health disorders so they’re relatable and easier to' +
        ' understand.<br><br>“Her gift shines through in this workbook. Annabella’s use of visuals and illustrative stories helped me to' +
        ' better understand the nature of anxiety. Not only that, she successfully communicated effective ways to' +
        ' cope with it.<br><br>“I know as individuals learn to utilize the lessons and practices contained in this workbook, they will be' +
        ' better equipped to manage their anxiety, overcome challenges and live a more fulfilling life.”',
      author: 'Alicia Matsuura',
    },
  ],
};

export default letGoAnxiety;
