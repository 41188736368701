import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import LetGoAnxiety from '../../components/Books/LetGoAnxiety';

export default props => (
  <>
    <SEO
      title="Let Go of Anxiety | Mindset Family Therapy"
      description="In Let Go of Anxiety: Climb Life's Mountains with Peace, Purpose, and Resilience, Annabella Hagen uses a powerful and effective behavioral treatment called ACT (acceptance and commitment therapy). This new book will change the way you view and respond to your anxiety, uncertainty, and self-doubt. Cultivate more self-compassion in your life."
      pathname="/books/let-go-of-anxiety"
    />
    <Layout>
      <LetGoAnxiety {...props} />
    </Layout>
  </>
);
